import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobilemenu"
export default class extends Controller {
  static targetContainer = null;

  initialize() {
    this.targetContainer = document.getElementsByTagName('body')[0];
  }

  toggle() {
    this.targetContainer.classList.toggle('open-mobile-menu');
  }

  close() {
    this.targetContainer.classList.remove('open-mobile-menu');
  }
}
