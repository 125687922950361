import { Application } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

window.Turbo = Turbo

Turbo.session.drive = false
Turbo.setProgressBarDelay(1)

const application = Application.start()

// Configure Stimulus development experience
application.debug = true
window.Stimulus = application

export { application }
